var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseBox',{staticClass:"relative flex justify-center h-full lg:py-12 xl:py-20"},[_c('BaseLink',{staticClass:"\n      bg-gray-900 bg-opacity-50\n      absolute\n      inset-0\n      w-full\n      h-full\n      cursor-default\n    ",attrs:{"to":_vm.closeRouteTo}}),_c('BaseBox',{staticClass:"relative max-w-screen-lg w-full"},[_c('BaseBox',{staticClass:"bg-white flex flex-col h-full lg:shadow lg:rounded"},[_c('BaseBox',{staticClass:"p-6"},[_c('BaseBox',{staticClass:"flex justify-end space-x-6",attrs:{"tag":"header"}},[_c('BaseBox',[_c('BaseLink',{staticClass:"hover:opacity-75",attrs:{"to":_vm.closeRouteTo}},[_c('BaseText',{staticClass:"sr-only"},[_c('i18n',{attrs:{"tag":false,"path":"close"}})],1),_c('BaseIcon',{attrs:{"name":"close-small","source":"custom"}})],1)],1)],1)],1),_c('BaseBox',{staticClass:"flex-1 overflow-auto px-6 pb-6"},[_c('BaseBox',{staticClass:"\n            grid grid-cols-2\n            gap-6\n            rounded-lg\n            overflow-hidden\n            transform-gpu\n          ",attrs:{"width-ratio":[
            375,
            'xs:639',
            'sm:767',
            'md:1023',
            'lg:1279',
            'xl:1535',
            '2xl:2047',
            '3xl:2048' ]}},_vm._l((_vm.galleryImageList),function(image,index){return _c('BaseLink',{key:image.imageId,class:[
              'relative bg-gray-200 aspect-w-16 aspect-h-9 filter transition-all hover:brightness-75',
              index % 3 ? '' : 'col-span-full',
              index % 3 === 1 && index === _vm.galleryImageListCount - 1
                ? 'col-span-full'
                : '' ],attrs:{"id":_vm.getImageId(image.imageKey),"to":_vm.getCostessaPhotoSlideShowRouteTo(image)}},[_c('BaseBox',{attrs:{"width-ratio":[index % 3 ? '6/12' : '12/12']}},[_c('BaseImage',{staticClass:"absolute w-full h-full object-cover",attrs:{"src":((image.path) + "/" + (image.name)),"alt":image.description || '',"loading":"lazy","decoding":"async"}})],1)],1)}),1)],1),(_vm.imageKey)?_c('Portal',{attrs:{"to":"route-secondary-modal-container"}},[_c('CostessaPhotoSlideShow',{attrs:{"image-key":_vm.imageKey}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }