var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseBox',{staticClass:"relative flex justify-center h-full lg:py-12 xl:py-20"},[_c('BaseBox',{staticClass:"bg-gray-900 absolute inset-0 w-full h-full"}),_c('BaseBox',{staticClass:"\n      relative\n      max-w-screen-lg\n      xl:max-w-screen-xl\n      2xl:max-w-screen-2xl\n      3xl:max-w-screen-3xl\n      w-full\n      h-full\n    "},[_c('BaseBox',{staticClass:"flex flex-col h-full"},[_c('BaseBox',{staticClass:"p-6"},[_c('BaseBox',{staticClass:"flex justify-end space-x-6",attrs:{"tag":"header"}},[_c('BaseBox',[_c('BaseLink',{staticClass:"text-white hover:opacity-75",attrs:{"to":_vm.closeRouteTo}},[_c('BaseText',{staticClass:"sr-only"},[_c('i18n',{attrs:{"tag":false,"path":"close"}})],1),_c('BaseIcon',{attrs:{"name":"close-small","source":"custom"}})],1)],1)],1)],1),_c('BaseBox',{staticClass:"flex-1 px-6 pb-6"},[_c('BaseBox',{staticClass:"xl:hidden aspect-w-16 aspect-h-9"},[_c('Swiper',{ref:"swiper",attrs:{"options":_vm.swiperOptions},scopedSlots:_vm._u([{key:"default",fn:function(){return _vm._l((_vm.galleryImageList),function(image){return _c('SwiperSlide',{key:image.imageId},[_c('BaseBox',{staticClass:"relative h-full",attrs:{"width-ratio":[
                    375,
                    'xs:639',
                    'sm:767',
                    'md:1023',
                    'lg:1279',
                    'xl:1535',
                    '2xl:2047',
                    '3xl:2048' ]}},[_c('BaseImage',{staticClass:"absolute w-full h-full object-contain",attrs:{"src":((image.path) + "/" + (image.name)),"alt":image.description || '',"loading":"lazy","decoding":"async"}})],1)],1)})},proxy:true},{key:"button-prev",fn:function(){return [_c('BaseBox',{staticClass:"swiper-button-prev swiper-button-white"})]},proxy:true},{key:"button-next",fn:function(){return [_c('BaseBox',{staticClass:"swiper-button-next swiper-button-white"})]},proxy:true}])})],1),_c('BaseBox',{staticClass:"hidden xl:block relative h-full",attrs:{"width-ratio":[
            375,
            'xs:639',
            'sm:767',
            'md:1023',
            'lg:1279',
            'xl:1535',
            '2xl:2047',
            '3xl:2048' ]}},[_c('Transition',{attrs:{"enter-active-class":"duration-200 ease-out","enter-class":"opacity-0","enter-to-class":"opacity-100","leave-active-class":"duration-100 ease-in","leave-class":"opacity-100","leave-to-class":"opacity-0"}},[_c('BaseImage',{key:_vm.imageKey,staticClass:"absolute w-full h-full object-contain",attrs:{"src":((_vm.costessaImage.path) + "/" + (_vm.costessaImage.name)),"alt":_vm.costessaImage.description || '',"loading":"lazy","decoding":"async"}})],1),_c('BaseBox',{staticClass:"\n              absolute\n              inset-0\n              w-full\n              h-full\n              pointer-events-none\n              flex\n              justify-between\n              items-center\n              px-4\n            "},[_c('BaseBox',{staticClass:"pointer-events-auto"},[(_vm.previousCostessaImageRouteTo)?_c('BaseLink',{staticClass:"text-white text-4xl cursor-pointer",attrs:{"to":_vm.previousCostessaImageRouteTo}},[_c('BaseText',{staticClass:"sr-only"},[_c('i18n',{attrs:{"tag":false,"path":"previous"}})],1),_c('BaseIcon',{attrs:{"name":"left-c","source":"custom"}})],1):_vm._e()],1),_c('BaseBox',{staticClass:"pointer-events-auto"},[(_vm.nextCostessaImageRouteTo)?_c('BaseLink',{staticClass:"text-white text-4xl cursor-pointer",attrs:{"to":_vm.nextCostessaImageRouteTo}},[_c('BaseText',{staticClass:"sr-only"},[_c('i18n',{attrs:{"tag":false,"path":"next"}})],1),_c('BaseIcon',{attrs:{"name":"right-c","source":"custom"}})],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }