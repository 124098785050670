













































































































































import {
  galleryImageList,
  getCostessaRouteTo,
  getCostessaPhotoSlideShowRouteTo,
} from '@/utils/costessa'
import { Swiper } from 'vue-awesome-swiper'
import { computed, defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'CostessaPhotoSlideShow',

  props: {
    imageKey: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const costessaImageIndex = computed(() => {
      const imageIndex = galleryImageList.findIndex(
        (image) => image.imageKey === props.imageKey
      )
      return imageIndex === -1 ? 0 : imageIndex
    })

    const costessaImage = computed(
      () => galleryImageList[costessaImageIndex.value]
    )

    const previousCostessaImageRouteTo = computed(() =>
      costessaImageIndex.value === 0
        ? undefined
        : getCostessaPhotoSlideShowRouteTo(
            galleryImageList[costessaImageIndex.value - 1]
          )
    )

    const nextCostessaImageRouteTo = computed(() =>
      costessaImageIndex.value === galleryImageList.length - 1
        ? undefined
        : getCostessaPhotoSlideShowRouteTo(
            galleryImageList[costessaImageIndex.value + 1]
          )
    )

    const closeRouteTo = computed(() => getCostessaRouteTo())

    const swiper = ref<InstanceType<typeof Swiper> | null>(null)

    const swiperOptions = computed(() => ({
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }))

    return {
      costessaImage,
      previousCostessaImageRouteTo,
      nextCostessaImageRouteTo,
      galleryImageList,
      closeRouteTo,
      swiper,
      swiperOptions,
    }
  },
})
