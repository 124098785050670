
















































































import CostessaPhotoSlideShow from '@/components/CostessaPhotoSlideShow.vue'
import { useWindowKeyboardListener } from '@/composition/windowKeyboardListener'
import {
  galleryImageList,
  getCostessaRouteTo,
  getCostessaPhotoSlideShowRouteTo,
} from '@/utils/costessa'
import router from '@/router'
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
} from '@vue/composition-api'

export default defineComponent({
  name: 'CostessaPhotoTour',

  components: {
    CostessaPhotoSlideShow,
  },

  props: {
    imageKey: {
      type: Number as PropType<number | null>,
      default: null,
    },

    scrollToImageKey: {
      type: Number as PropType<number | null>,
      default: null,
    },
  },

  setup(props) {
    const galleryImageListCount = computed(() => galleryImageList.length)

    onMounted(() => {
      if (props.scrollToImageKey) {
        const photoEl = document.getElementById(
          getImageId(props.scrollToImageKey)
        )

        if (photoEl) {
          photoEl.scrollIntoView()
        }
      }
    })

    function getImageId(imageKey: number) {
      return `photo-${imageKey}`
    }

    const closeRouteTo = computed(() => getCostessaRouteTo())

    useWindowKeyboardListener({
      key: 'Escape',
      handler: (evt) => {
        if (
          evt &&
          evt.target &&
          ['INPUT', 'TEXTAREA'].includes((evt.target as HTMLElement).nodeName)
        )
          return

        router.push(closeRouteTo.value)
      },
    })

    return {
      getCostessaPhotoSlideShowRouteTo,
      closeRouteTo,
      galleryImageList,
      galleryImageListCount,
      getImageId,
    }
  },
})
